<template>
  <a-tabs
    v-model:activeKey="activeRoute"
    class="tabs"
    type="card"
    @tab-click="handleTabClick"
  >
    <a-tab-pane v-for="tag in routeTags" :key="tag.path">
      <template #tab>
        {{ tag.meta.title }}
        <RedoOutlined
          v-if="activeRoute === tag.path"
          :rotate="270"
          class="tabs-reload"
          @click.stop="refreshPage"
        />
        <CloseOutlined
          v-if="tag.name !== 'home'"
          class="tabs-close"
          @click.stop="handleTabRemove(tag)"
        />
      </template>
    </a-tab-pane>
  </a-tabs>
</template>

<script lang="ts" setup>
/**引入vue依赖 */
import { computed, onMounted, ref, watch } from 'vue';

/**引入路由 */
import { useRoute, useRouter } from 'vue-router';

/**引入图标 */
import { CloseOutlined, RedoOutlined } from '@ant-design/icons-vue';

/**引入vuex */
import { useAppStore } from '@/store/appStore';
import { IRouteTag } from '@/store/interface';

/**获取store函数 */
const appStore = useAppStore();
/**声明选中的route */
const activeRoute = ref('');

// refreshPage: () => props.reload();

const props = defineProps<{ reload: () => void }>();

const refreshPage = () => props.reload();

/**监听路由变动 */
const route = useRoute();
onMounted(() => {
  if (route.meta.title && !route.meta.inside) {
    appStore.addRouteTag({
      name: route.name as string,
      path: route.path,
      meta: route.meta,
    });

    activeRoute.value = route.path;
  }
});
watch(
  () => route.path,
  () => {
    if (route.meta.title && !route.meta.inside) {
      appStore.addRouteTag({
        name: route.name as string,
        path: route.path,
        meta: route.meta,
      });
      activeRoute.value = route.path;
    }
  },
);

/**获取当前路由信息 */
const routeTags = computed(() => appStore.routeTags);

/**处理删除tab事件 */
const handleTabRemove = (view: IRouteTag) => {
  appStore.removeRouteTag(view.path);
  toLastView();
};

/**获取最后一个可渲染tab */
const toLastView = () => {
  const latestView = routeTags.value.slice(-1)[0];
  if (latestView !== undefined && latestView.path !== undefined) {
    router.push({ path: latestView.path });
  } else {
    router.push('/');
  }
};

/**设置路由跳转 */
const router = useRouter();
/**处理tab点击事件 */
const handleTabClick = (path: string) =>
  activeRoute.value !== path && router.push({ path: path });
</script>

<style lang="scss" scoped>
.tabs {
  text-align: left;
  user-select: none;

  :deep(.anticon) {
    margin-right: 0 !important;
    margin-left: 8px !important;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    transition: color 0.1s;
  }

  &-reload {
    &:hover {
      color: #1890ff;
    }
  }

  &-close {
    &:hover {
      color: #333;
    }
  }
}
</style>
