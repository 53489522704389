import { RouteRecordRaw } from 'vue-router';
import { Role } from '@/router/enum';

export const androidScreenRoute: RouteRecordRaw = {
  path: 'android-screen',
  name: 'android-screen',
  meta: {
    title: '安卓屏',
    key: 'android-screen',
    icon: 'fund-projection-screen-outlined',
    permission: [Role.ADMIN, Role.USER, Role.ANDROID_ADMIN],
  },
  children: [
    {
      path: 'android',
      name: 'android',
      meta: {
        title: '安卓应用',
        key: 'android',
        icon: 'android-outlined',
        permission: [Role.ADMIN, Role.USER, Role.ANDROID_ADMIN],
      },
      children: [
        {
          path: 'android-publish',
          name: 'android-publish',
          component: () =>
            import('@/views/back-stage-management/Android/index.vue'),
          meta: {
            title: '发布',
            key: 'android-publish',
            icon: 'cloud-upload-outlined',
            permission: [Role.ADMIN, Role.USER, Role.ANDROID_ADMIN],
          },
        },
        {
          path: 'android-project',
          name: 'android-project',
          component: () =>
            import('@/views/back-stage-management/Android/page/project.vue'),
          meta: {
            title: '项目列表',
            key: 'android-project',
            icon: 'ProfileOutlined',
            permission: [Role.ADMIN, Role.ANDROID_ADMIN],
          },
        },
      ],
    },

    {
      path: 'android-system',
      name: 'android-system',
      meta: {
        title: '安卓系统',
        key: 'android-system',
        icon: 'aim-outlined',
        permission: [Role.ADMIN, Role.USER, Role.ANDROID_ADMIN],
      },
      children: [
        {
          path: 'android-system-publish',
          name: 'android-system-publish',
          component: () =>
            import(
              '@/views/back-stage-management/Android/page/system/system-publish.vue'
            ),
          meta: {
            title: '发布',
            key: 'android-system-publish',
            icon: 'cloud-upload-outlined',
            permission: [Role.ADMIN, Role.USER, Role.ANDROID_ADMIN],
          },
        },
        {
          path: 'android-system-project',
          name: 'android-system-project',
          component: () =>
            import(
              '@/views/back-stage-management/Android/page/system/system-project.vue'
            ),
          meta: {
            title: '项目列表',
            key: 'android-system-project',
            icon: 'ProfileOutlined',
            permission: [Role.ADMIN, Role.ANDROID_ADMIN],
          },
        },
      ],
    },

    {
      path: 'android-device',
      name: 'android-device',
      component: () =>
        import('@/views/back-stage-management/Android/page/sn.vue'),
      meta: {
        title: '设备SN',
        key: 'android-device',
        icon: 'RobotOutlined',
        permission: [Role.ADMIN, Role.ANDROID_ADMIN],
      },
    },
  ],
};
