import { userApi } from '@/api/request/user';
import router from '@/router';
import { message } from 'ant-design-vue';
import md5 from 'md5';
import { useAppStore } from '@/store/appStore';
import { Toast } from '@/enum/ui';
import { dingtalkApi } from '@/api/request/dingtalk';
import { LoginSessionKey } from '@/enum/app';

export interface ILoginFormProp {
  username: string;
  password: string;
}

/**格式化密码 */
export const formatPassword = ({
  username,
  password,
}: ILoginFormProp): string => md5(md5(username) + md5(password));

/**登录函数 */
export const loginMethod = async (
  loginParam: ILoginFormProp,
  isSilence = false,
) => {
  try {
    const { data } = await userApi.login(loginParam);
    const appStore = useAppStore();

    if (data.status === 200) {
      // 登录信息 -- 同步
      appStore.setLoginState(data.data);

      if (data.data.dd_union_id) {
        const { data: d } = await dingtalkApi.signDingTalk(
          data.data.dd_union_id,
        );

        appStore.setDDToken(d);
        const { data: e } = await dingtalkApi.getPermission();
        e.status === 200 && appStore.setIAMPermission(e.data);
      }

      sessionStorage.setItem(
        LoginSessionKey.LOGIN_PARAM,
        JSON.stringify(loginParam),
      );
      if (!isSilence) {
        message.success(data.message);
        await router.push('/');
      }
    } else {
      !isSilence && message.error(data.message);
    }
  } catch (error) {
    message.error({ content: '网络错误', key: Toast.NetError });
    sessionStorage.removeItem(LoginSessionKey.LOGIN_PARAM) /**清空登录信息 */;
    await router.push('/login');
  }
};

export async function loginByUnionId(union_id: string, isSilence = false) {
  try {
    const appStore = useAppStore();

    const { data } = await userApi.dingtalkLogin(union_id);
    if (data.status === 200) {
      appStore.setLoginState(data.data);
      // TODO 刷新刷新刷新
      sessionStorage.setItem(LoginSessionKey.UNION_ID, union_id);

      if (data.data.dd_union_id) {
        const { data: d } = await dingtalkApi.signDingTalk(
          data.data.dd_union_id,
        );

        appStore.setDDToken(d);
        const { data: e } = await dingtalkApi.getPermission();
        e.status === 200 && appStore.setIAMPermission(e.data);
      }

      if (!isSilence) {
        message.success(data.message);
        await router.push('/');
      }
    } else {
      message.error(data.message);
    }
  } catch (e) {
    message.error({ content: '网络错误', key: Toast.NetError });
    sessionStorage.removeItem(LoginSessionKey.LOGIN_PARAM) /**清空登录信息 */;
    await router.push('/login');
  }
}

/**
 * 刷新token
 * */
export async function refreshToken() {
  try {
    const { data } = await userApi.refreshToken();
    const appStore = useAppStore();

    if (data.status === 200) {
      appStore.setLoginState(data.data);
    } else {
      await router.push('/login');
    }
  } catch (error) {
    message.error('网络错误');
    sessionStorage.removeItem(LoginSessionKey.LOGIN_PARAM) /**清空登录信息 */;
    await router.push('/login');
  }
}
