import { useAppStore } from '@/store/appStore';
import { Directive } from 'vue';
import { Role } from '@/router/enum';

/**
 * 判断是否为Linux管理员
 * */
const linuxAdminDirective: Directive = {
  mounted(el) {
    const appStore = useAppStore();
    !appStore.isAdmin &&
      !appStore.permission.includes(Role.LINUX_ADMIN) &&
      el.parentNode &&
      el.parentNode.removeChild(el);
  },
};

export default linuxAdminDirective;
