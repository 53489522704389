import { Role } from '@/router/enum';
import { RouteRecordRaw } from 'vue-router';

export const firmwareRoute: RouteRecordRaw = {
  path: 'firmware',
  name: 'firmware',
  component: () => import('@/views/back-stage-management/Firmware/index.vue'),
  meta: {
    title: '固件',
    key: 'firmware',
    icon: 'DeliveredProcedureOutlined',
    permission: [Role.ADMIN, Role.USER, Role.LINUX_ADMIN],
  },
};
