import { useAppStore } from '@/store/appStore';
import { Directive } from 'vue';

/**
 * 判断是否为管理员
 * */
const adminDirective: Directive = {
  mounted(el) {
    const appStore = useAppStore();
    !appStore.isAdmin && el.parentNode && el.parentNode.removeChild(el);
  },
};

export default adminDirective;
