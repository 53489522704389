import { useAppStore } from '@/store/appStore';
import { computed } from 'vue';
import { Role } from '@/router/enum';

export default function useLinuxAdmin() {
  const store = useAppStore();

  return computed(
    () => store.isAdmin || store.permission.includes(Role.LINUX_ADMIN),
  );
}
