import { RouteRecordRaw } from 'vue-router';
import { Role } from '@/router/enum';

export const softwareRouter: RouteRecordRaw = {
  path: 'software',
  name: 'software',
  redirect: 'software/software-release',
  meta: {
    title: '上位机',
    key: 'software',
    icon: 'block-outlined',
    permission: [Role.ADMIN, Role.USER],
  },
  children: [
    {
      path: 'software-release',
      name: 'software-release',
      component: () =>
        import(
          '@/views/back-stage-management/SoftwareRelease/electron/electron-release.vue'
        ),
      meta: {
        title: '发布(Electron)',
        key: 'software-release',
        icon: 'aim-outlined',
        permission: [Role.ADMIN, Role.USER],
      },
    },

    {
      path: 'wpf-software-release',
      name: 'wpf-software-release',
      component: () =>
        import(
          '@/views/back-stage-management/SoftwareRelease/wpf/wpf-release.vue'
        ),
      meta: {
        title: '发布(WPF)',
        key: 'wpf-software-release',
        icon: 'aim-outlined',
        permission: [Role.ADMIN, Role.USER],
      },
    },

    {
      path: 'software-param',
      name: 'software-param',
      component: () =>
        import('@/views/back-stage-management/UpperSoftware/index.vue'),
      meta: {
        title: '参数',
        key: 'software-param',
        icon: 'block-outlined',
        permission: [Role.ADMIN, Role.USER],
      },
    },
  ],
};
