import { Role } from '@/router/enum';
import { RouteRecordRaw } from 'vue-router';

export const reactNativeRoute: RouteRecordRaw = {
  path: 'react_native',
  name: 'react_native',
  component: () =>
    import('@/views/back-stage-management/ReactNative/index.vue'),
  meta: {
    title: 'RN应用',
    key: 'react_native',
    icon: 'AppstoreOutlined',
    permission: [Role.ADMIN, Role.USER, Role.LINUX_ADMIN],
  },
};
