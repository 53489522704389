<template>
  <a-menu-item
    v-if="
      intersection(item.meta?.permission, roles).length > 0 &&
      !item.meta?.inside
    "
    :key="item.name"
  >
    <template #icon>
      <component
        :is="item.meta?.icon"
        v-if="item.meta?.icon"
        :style="{ fontSize: '16px' }"
      />
    </template>
    <span>{{ item.meta?.title }}</span>
  </a-menu-item>
</template>

<script lang="ts" setup>
import { RouteRecordRaw } from 'vue-router';
import useAdmin from '@/hooks/permission/useAdmin';
import useLinuxAdmin from '@/hooks/permission/useLinuxAdmin';
import useAndroidAdmin from '@/hooks/permission/useAndroidAdmin';
import { computed } from 'vue';
import { Role } from '@/router/enum';
import { intersection } from 'lodash';
import useForkliftAdmin from '@/hooks/permission/useForkliftAdmin';

defineOptions({ name: 'LayoutMenuItem' });
defineProps<{ item: RouteRecordRaw }>();

const isAdmin = useAdmin();
const isLinuxAdmin = useLinuxAdmin();
const isAndroidAdmin = useAndroidAdmin();
const isForkliftAdmin = useForkliftAdmin();

const roles = computed(() => {
  const role = [Role.USER];

  if (isLinuxAdmin.value) {
    role.push(Role.LINUX_ADMIN);
  }

  if (isAndroidAdmin.value) {
    role.push(Role.ANDROID_ADMIN);
  }

  if (isAdmin.value) {
    role.push(Role.ADMIN);
  }

  if (isForkliftAdmin.value) {
    role.push(Role.FORKLIFT_ADMIN);
  }
  return role;
});
</script>

<style lang="scss" scoped></style>
