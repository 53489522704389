<template>
  <header>
    <div class="drawer-handle showMobile" @click="switchDrawer">
      <MenuOutlined />
    </div>
    <a-dropdown>
      <p class="user">
        <span class="hideMobile">当前用户：</span>{{ user }}
        <DownOutlined style="font-size: 12px" />
      </p>

      <template #overlay>
        <a-menu @click="onContextMenuClick">
          <a-menu-item key="1">登出</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </header>

  <a-drawer
    v-model:open="visible"
    :placement="'left'"
    :closable="false"
    :width="200"
    :header-style="{ 'border-bottom': 'none' }"
    :body-style="{
      padding: 0,
      height: 'calc(100% - 78px)',
    }"
  >
    <template #title>
      <div class="menu-header">
        <p>{{ APP_TITLE }}</p>
      </div>
    </template>

    <LayoutMenu :show-logo="false" @item-click="visible = false" />
  </a-drawer>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

import { Modal } from 'ant-design-vue';
import { DownOutlined, MenuOutlined } from '@ant-design/icons-vue';

import { useRouter } from 'vue-router';

import { useAppStore } from '@/store/appStore';
import { APP_TITLE } from '../config';
import { LoginSessionKey } from '@/enum/app';
import LayoutMenu from '@/layout/components/layout-menu.vue';

const appStore = useAppStore();
const router = useRouter();

const user = computed(() => appStore.user);

const onContextMenuClick = ({ key }: { key: string }) => {
  if (key === '1') {
    Modal.confirm({
      title: '确定要退出当前登录吗?',
      okText: '退出',
      cancelText: '取消',
      onOk: () => {
        // 重置token
        appStore.clearLoginState();

        // 清空登录信息
        sessionStorage.removeItem(LoginSessionKey.LOGIN_PARAM);
        sessionStorage.removeItem(LoginSessionKey.UNION_ID);
        router.replace('/login');
      },
      onCancel: () => null,
    });
  }
};

const visible = ref<boolean>(false);
const switchDrawer = () => (visible.value = !visible.value);
</script>

<style lang="scss" scoped>
header {
  height: 48px;
  line-height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 0;
  justify-content: space-between;
  box-shadow: 0 6px 5px -5px #afaeaa99;
  position: sticky;
  z-index: 500;
  background: #fff;
  top: 0;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 32px;

    img {
      height: 42px;
      width: 42px;
    }

    p {
      margin: 0 0 0 12px;
      overflow: hidden;
      font-weight: 600;
      color: #333;
      font-size: 18px;
      line-height: 32px;
    }
  }

  .user {
    color: #333;
    font-size: 16px;
    margin: 0;
    cursor: pointer;
  }
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: center;
  //line-height: 32px;

  img {
    height: 46px;
    width: 46px;
  }

  p {
    margin: 0;
    overflow: hidden;
    font-weight: 600;
    color: #333;
    font-size: 16px;
    line-height: 32px;
  }
}

.drawer-handle {
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
