import { Role } from '@/router/enum';
import { RouteRecordRaw } from 'vue-router';

export const adminRoute: RouteRecordRaw[] = [
  {
    path: 'user',
    name: 'user',
    component: () => import('@/views/back-stage-management/User/index.vue'),
    meta: {
      title: '用户',
      key: 'user',
      icon: 'TeamOutlined',
      permission: [Role.ADMIN],
    },
  },
  {
    path: 'logger',
    name: 'logger',
    component: () => import('@/views/back-stage-management/Logger/index.vue'),
    meta: {
      title: '日志',
      key: 'logger',
      icon: 'CalendarOutlined',
      permission: [Role.ADMIN],
    },
  },
];
