import { AxiosPromise } from 'axios';
import service from '@/api/instance/public-request';
import {
  AuditForkliftResultDto,
  DingtalkPermissionListResponse,
  DingTalkPermissionResponse,
  DingtalkSetPermissionDto,
  DingTalkUserListResponse,
} from '@/api/request/dingtalk/interface';
import { ApiCommResponse } from '@/api/request/interface';

export const dingtalkApi = {
  /**
   * 获取钉钉token
   */
  signDingTalk: (unionId: string): AxiosPromise<string> =>
    service.post('/dingding/sign_union_id', { unionId }),

  /**
   * 钉钉用户列表
   */
  userList: (): AxiosPromise<DingTalkUserListResponse> =>
    service.post('/dingding/user_list'),

  /**
   * 获取用户权限列表
   */
  getPermission: (): AxiosPromise<DingTalkPermissionResponse> =>
    service.post('/dingding/get_permission'),

  /**
   * 配置权限
   */
  setPermission: (
    dto: DingtalkSetPermissionDto,
  ): AxiosPromise<ApiCommResponse> =>
    service.post('/dingding/set_permission', dto),

  /**
   * 获取权限分配列表
   * */
  permissionList: (): AxiosPromise<DingtalkPermissionListResponse> =>
    service.post('/dingding/permission_list'),

  /**
   * 审核某个叉车新增申请
   * */
  auditForkliftResult: (
    data: AuditForkliftResultDto,
  ): AxiosPromise<ApiCommResponse> =>
    service.post('/dingding/audit_forklift_result', data),
};
