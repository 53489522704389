import axios, { RawAxiosRequestHeaders } from 'axios';
import { PUBLIC_URL } from '@/api/config';
import { useAppStore } from '@/store/appStore';

/**设置超时时间与基础请求地址 */
const service = axios.create({
  baseURL: PUBLIC_URL,
});

/**axios前置拦截器 */
service.interceptors.request.use((config) => {
  const store = useAppStore();

  (config.headers as RawAxiosRequestHeaders)['x-acs-systech-access-token'] =
    store.dd_token || '';

  return config;
});

export default service;
