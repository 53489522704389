<template>
  <Menu class="hideMobile" :show-logo="true" />

  <div class="main-container">
    <Header />
    <div class="content-container">
      <Tab :reload="reload" />
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref } from 'vue';
import Header from '@/layout/components/layout-header.vue';
import Tab from '@/layout/components/layout-tab.vue';
import Menu from '@/layout/components/layout-menu.vue';

const isRouterAlive = ref(true);

const reload = async () => {
  isRouterAlive.value = false;
  await nextTick();
  isRouterAlive.value = true;
};
</script>

<style lang="scss" scoped>
.main-container {
  flex: 1;
  max-height: 100vh;
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-container {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  min-height: calc(100% - 48px);
  background: #fff;
}

@media (max-width: 1014px) {
  .content-container {
    width: 100vw !important;
  }
}
</style>
