import { Role } from '@/router/enum';
import { RouteRecordRaw } from 'vue-router';

export const forkliftFirmwareRoute: RouteRecordRaw = {
  path: 'forklift',
  name: 'forklift',
  meta: {
    title: '叉车固件',
    key: 'firmware',
    icon: 'ClusterOutlined',
    permission: [Role.ADMIN, Role.USER],
  },
  children: [
    {
      path: 'forklift-publish-overview',
      name: 'forklift-publish-overview',
      component: () =>
        import(
          '@/views/back-stage-management/Firmware/forklift/overview/overview-page.vue'
        ),
      meta: {
        title: '固件总览',
        key: 'forklift-publish-overview',
        icon: 'appstore-outlined',
        permission: [Role.ADMIN, Role.USER],
      },
    },
    {
      path: 'forklift-publish',
      name: 'forklift-publish',
      component: () =>
        import(
          '@/views/back-stage-management/Firmware/forklift/hardware/forklift-hardware.vue'
        ),
      meta: {
        title: '固件发布',
        key: 'forklift-publish',
        icon: 'cloud-upload-outlined',
        permission: [Role.ADMIN, Role.USER],
      },
    },
    {
      path: 'forklift-publish-detail',
      name: 'forklift-publish-detail',
      component: () =>
        import(
          '@/views/back-stage-management/Firmware/forklift/firmware/forklift-firmware-detail.vue'
        ),
      meta: {
        title: '固件列表',
        key: 'forklift-publish-detail',
        icon: 'cloud-upload-outlined',
        inside: true,
        permission: [Role.ADMIN, Role.USER],
      },
    },
    {
      path: 'forklift-map-code-detail',
      name: 'forklift-map-code-detail',
      component: () =>
        import(
          '@/views/back-stage-management/Firmware/forklift/firmware/forklift-map-code-detail.vue'
        ),
      meta: {
        title: '图号列表',
        key: 'forklift-map-code-detail',
        icon: 'cloud-upload-outlined',
        inside: true,
        permission: [Role.ADMIN, Role.USER],
      },
    },
    {
      path: 'forklift-driver',
      name: 'forklift-driver',
      component: () =>
        import(
          '@/views/back-stage-management/Firmware/forklift/driver/forklift-driver.vue'
        ),
      meta: {
        title: '驱动备份',
        key: 'forklift-driver',
        icon: 'PicCenterOutlined',
        permission: [Role.ADMIN, Role.USER],
      },
    },
    {
      path: 'forklift-permission',
      name: 'forklift-permission',
      component: () =>
        import(
          '@/views/back-stage-management/Firmware/forklift/permission/forklift-permission.vue'
        ),
      meta: {
        title: '权限配置',
        key: 'forklift-permission',
        icon: 'SolutionOutlined',
        permission: [Role.ADMIN],
      },
    },
  ],
};
