import { defineStore } from 'pinia';
import { IRouteTag } from '@/store/interface';
import { UserLoginRes } from '@/api/request/user/interface';
import { FirmwarePermission } from '@/api/request/forklift/enum';

type BaseUserState = Omit<UserLoginRes, 'permission'>;

interface NetUserState extends BaseUserState {
  /**页面权限 */
  permission: string;
}

interface AppState extends BaseUserState {
  /**页面权限 */
  permission: string[];

  /**缓存的路由信息 */
  routeTags: IRouteTag[];

  /**钉钉 token */
  dd_token: string;

  // IAM权限列表
  iamPermission: FirmwarePermission[];
}

const DEFAULT_ROUTE_TAGS = [
  {
    name: 'home',
    path: '/home',
    meta: {
      title: '首页',
      key: 'home',
    },
  },
];

export const useAppStore = defineStore('device', {
  state: (): AppState => ({
    user: '' /**用户名 */,
    token: '' /**token */,
    isAdmin: false,
    permission: [],
    dd_union_id: '',
    routeTags: [...DEFAULT_ROUTE_TAGS],

    iamPermission: [],
    dd_token: '',
  }),
  getters: {
    admin: (state) => state.isAdmin,
    fkPermission: (state) => (v: FirmwarePermission | FirmwarePermission[]) => {
      const { iamPermission } = state;
      let verify = false;
      // 数组
      if (Array.isArray(v)) {
        for (let i = 0; i < iamPermission.length; i++) {
          const item = iamPermission[i];
          if (v.includes(item)) {
            verify = true;
            break;
          }
        }
      }
      // 普通
      else {
        verify = iamPermission.includes(v);
      }

      return verify;
    },
  },
  actions: {
    /**设置登录状态 */
    setLoginState(dto: NetUserState) {
      const { token, user, isAdmin, permission, dd_union_id } = dto;
      this.token = token;
      this.user = user;
      this.isAdmin = isAdmin;
      this.permission = permission.split(',');
      this.dd_union_id = dd_union_id;
    },

    /**
     * 设置钉钉token
     * @param {string} token
     * */
    setDDToken(token: string) {
      this.dd_token = token;
    },

    /**
     * 设置钉钉token
     * @param {FirmwarePermission[]} permission
     * */
    setIAMPermission(permission: FirmwarePermission[]) {
      this.iamPermission = permission;
    },

    /**清空登录状态 */
    clearLoginState() {
      this.token = '';
      this.user = '';
      this.isAdmin = false;
      this.permission = [];
      this.dd_union_id = '';
      this.dd_token = '';
      this.iamPermission = [];
      this.routeTags = [...DEFAULT_ROUTE_TAGS];
    },

    /**添加路由tag */
    addRouteTag(view: IRouteTag) {
      if (this.routeTags.some((v) => v.path === view.path)) return;
      this.routeTags.push(
        Object.assign({}, view, {
          title: (view.meta && view.meta.title) || 'no-name',
        }),
      );
    },

    /**删除路由tag */
    removeRouteTag(path: string) {
      for (const [i, v] of this.routeTags.entries()) {
        if (v.path === path) {
          this.routeTags.splice(i, 1);
          break;
        }
      }
    },
  },
});
