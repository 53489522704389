import { useAppStore } from '@/store/appStore';
import { Directive, DirectiveBinding } from 'vue';
import { Role } from '@/router/enum';
import { FirmwarePermission } from '@/api/request/forklift/enum';

/**
 * 判断是否为Linux管理员
 * */
const ForkliftPermissionDirective: Directive = {
  mounted(
    el,
    binding: DirectiveBinding<FirmwarePermission | FirmwarePermission[]>,
  ) {
    const v = binding.value;
    const { iamPermission } = useAppStore();

    let verify = false;
    // 数组
    if (Array.isArray(v)) {
      for (let i = 0; i < iamPermission.length; i++) {
        const item = iamPermission[i];
        if (v.includes(item)) {
          verify = true;
          break;
        }
      }
    }
    // 普通
    else {
      verify = iamPermission.includes(v);
    }

    if (!verify) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
};

export default ForkliftPermissionDirective;
