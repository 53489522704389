<template>
  <a-menu
    class="menu"
    :selected-keys="[selectedKeys]"
    mode="inline"
    @click="handleClick"
  >
    <div v-if="prop.showLogo" class="menu-header">
      <p>{{ APP_TITLE }}</p>
    </div>

    <template v-for="item in routeData" :key="item.name">
      <!--   一级菜单   -->
      <LayoutMenuItem
        v-if="!item?.children || item?.children.length === 0"
        :item="item"
      />

      <!--   二级菜单   -->
      <LayoutMenuSubItem v-else :item="item" />
    </template>
  </a-menu>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, toRaw, watch } from 'vue';

import { routes } from '@/router';
import { RouteRecordRaw, useRoute, useRouter } from 'vue-router';

import { APP_TITLE } from '../config';
import useAdmin from '@/hooks/permission/useAdmin';
import useLinuxAdmin from '@/hooks/permission/useLinuxAdmin';
import LayoutMenuItem from '@/layout/components/layout-menu-item.vue';
import LayoutMenuSubItem from '@/layout/components/layout-menu-sub-item.vue';
import { Role } from '@/router/enum';
import useAndroidAdmin from '@/hooks/permission/useAndroidAdmin';
import { intersection } from 'lodash';
import useForkliftAdmin from '@/hooks/permission/useForkliftAdmin';
import { useAppStore } from '@/store/appStore';

defineOptions({ name: 'LayoutMenu' });

const router = useRouter();
const handleClick = (e: { key: string }) => {
  router.push({ name: e.key });
  emit('itemClick');
};

const prop = defineProps<{ showLogo: boolean }>();
const emit = defineEmits(['itemClick']);

/**监听路由变动 */
const route = useRoute();
const routeKey = ref<string>('home');
const routeData = ref<RouteRecordRaw[]>([]);

const isAdmin = useAdmin();
const isLinuxAdmin = useLinuxAdmin();
const isAndroidAdmin = useAndroidAdmin();
const isForkliftAdmin = useForkliftAdmin();

const appStore = useAppStore();

onMounted(() => {
  routeKey.value = route.meta.key + '';
  updateRouteArr();
});

watch(
  () => route.meta,
  (newValue) => (routeKey.value = newValue?.key + ''),
);

watch(
  () => [
    isAdmin.value,
    isLinuxAdmin.value,
    isAndroidAdmin.value,
    isForkliftAdmin.value,
  ],
  () => updateRouteArr(),
);

// TODO 权限系统需要重构
const updateRouteArr = () => {
  const roles = [Role.USER];

  if (isLinuxAdmin.value) {
    roles.push(Role.LINUX_ADMIN);
  }

  if (isAndroidAdmin.value) {
    roles.push(Role.ANDROID_ADMIN);
  }

  if (isAdmin.value) {
    roles.push(Role.ADMIN);
  }

  // if (isForkliftAdmin.value) {
  //   roles.push(Role.FORKLIFT_ADMIN);
  // }

  const filteredRoutes = (routes[0].children || []).filter(
    (e) =>
      intersection(e.meta?.permission, roles).length > 0 && !e.meta?.inside,
  );

  const hasFirmware =
    toRaw(appStore.permission).filter((e) => e.includes('project_')).length >
      0 || isAdmin.value;

  if (!hasFirmware) {
    const i = filteredRoutes.findIndex((e) => e.name === 'firmware');
    i > -1 && filteredRoutes.splice(i, 1);
  }

  const hansUpperSoft =
    toRaw(appStore.permission).filter((e) => e.includes('upperSoftware_'))
      .length > 0 || isAdmin.value;

  const hasAndroid =
    toRaw(appStore.permission).filter((e) => e.includes('Android_')).length >
      0 ||
    isAdmin.value ||
    isAndroidAdmin.value;

  const hasAndroidSystem =
    toRaw(appStore.permission).filter((e) => e.includes('AndroidSystem_'))
      .length > 0 ||
    isAdmin.value ||
    isAndroidAdmin.value;

  if (!hasAndroid && !hasAndroidSystem) {
    const i = filteredRoutes.findIndex((e) => e.name === 'android-screen');
    i > -1 && filteredRoutes.splice(i, 1);
  }

  const hasSoftware =
    toRaw(appStore.permission).filter((e) => e.includes('Software_')).length >
      0 || isAdmin.value;
  if (!hasSoftware) {
    const i = filteredRoutes.findIndex((e) => e.name === 'software');
    i > -1 && filteredRoutes.splice(i, 1);
  }

  const hasRN =
    toRaw(appStore.permission).filter((e) => e.includes('ReactNative_'))
      .length > 0 || isAdmin.value;

  if (!hasRN) {
    const i = filteredRoutes.findIndex((e) => e.name === 'react_native');
    i > -1 && filteredRoutes.splice(i, 1);
  }

  const hasLinux =
    toRaw(appStore.permission).filter((e) => e.includes('Linux_')).length > 0 ||
    isAdmin.value ||
    isLinuxAdmin.value;

  if (!hasLinux) {
    const i = filteredRoutes.findIndex((e) => e.name === 'linux');
    i > -1 && filteredRoutes.splice(i, 1);
  }

  routeData.value = filteredRoutes;
};

const selectedKeys = computed(() => route.meta.key);
</script>

<style lang="scss" scoped>
.menu-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  line-height: 32px;
  box-shadow: 0 3px 3px -3px rgba(175, 174, 170, 0.6);

  img {
    height: 46px;
    width: 46px;
  }

  p {
    //margin: 0 0 0 12px;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #333;
    font-size: 18px;
    //line-height: 32px;
  }
}

.menu {
  width: 200px;
  margin-right: 8px;
  border-radius: 6px;

  :deep(.ant-menu-item),
  :deep(.ant-menu-submenu-title) {
    text-align: left;
    padding-left: 30px !important;
  }

  :deep(.ant-menu-inline .ant-menu-item) {
    text-align: left;
    padding-left: 40px !important;
  }
}
</style>
