import { RouteRecordRaw } from 'vue-router';
import { Role } from '@/router/enum';

export const publicRoute: RouteRecordRaw[] = [
  {
    path: '/download-zone',
    name: 'DownloadZone',
    component: () => import('@/views/download-zone/index.vue'),
    meta: {
      title: '下载中心',
      key: 'download-zone',
      permission: [Role.FREE],
    },
  },
  {
    path: '/download-zone/:key',
    name: 'DownloadZoneDetail',
    component: () => import('@/views/download-zone/index.vue'),
    meta: {
      title: '下载中心',
      key: 'download-zone-detail',
      permission: [Role.FREE],
    },
  },

  {
    path: '/linux-zone',
    name: 'LinuxZone',
    component: () => import('@/views/linux-zone/index.vue'),
    meta: {
      title: '下载中心',
      key: 'linux-zone',
      permission: [Role.FREE],
    },
  },
  {
    path: '/linux-zone/:key',
    name: 'LinuxZoneDetail',
    component: () => import('@/views/linux-zone/index.vue'),
    meta: {
      title: '下载中心',
      key: 'linux-zone-detail',
      permission: [Role.FREE],
    },
  },
];
