import { Component, createApp } from 'vue';
import './style.css';
import App from './App.vue';
import Antd from 'ant-design-vue';
import router from '@/router';
import { createPinia } from 'pinia';
import 'ant-design-vue/dist/reset.css';
import * as Icons from '@ant-design/icons-vue';
import adminDir from '@/directives/admin-directive';
import linuxAdminDir from '@/directives/linux-admin-directive';
import forkliftPermissionDir from '@/directives/forklift-permission-directive';
// import * as Sentry from '@sentry/vue';

const app = createApp(App);

// 注册图标组件
for (const i in Icons) {
  app.component(i, (Icons as Record<string, Component>)[i]);
}

// Sentry.init({
//   app,
//   dsn: 'http://c6e711a23aa11f02016d20a7c9616833@sentry.systech.com.cn/8',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/ota\.systech\.com\.cn/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

app.directive('admin', adminDir);
app.directive('linux-admin', linuxAdminDir);
app.directive('forklift-permission', forkliftPermissionDir);

app.use(createPinia()).use(router).use(Antd).mount('#app');
