import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { loginByUnionId, loginMethod } from '@/utils/login';

import Layout from '@/layout/index.vue';
import { useAppStore } from '@/store/appStore';
import { softwareRouter } from '@/router/module/software';
import { linuxRoute } from '@/router/module/linux';
import { androidScreenRoute } from '@/router/module/android-screen';
import { reactNativeRoute } from '@/router/module/reactnative';
import { firmwareRoute } from '@/router/module/firmware';
import { adminRoute } from '@/router/module/admin';
import { publicRoute } from '@/router/module/public';
import { forkliftFirmwareRoute } from '@/router/module/forklift-firmware';
import { Role } from '@/router/enum';
import { LoginSessionKey } from '@/enum/app';

declare module 'vue-router' {
  interface RouteMeta {
    title: string;
    key: string;
    icon?: string;
    permission: Role[];
    inside?: boolean;
  }
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,
    name: 'Home',
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/back-stage-management/Home/index.vue'),
        meta: {
          title: '首页',
          key: 'home',
          icon: 'HomeOutlined',
          permission: [Role.ADMIN, Role.USER, Role.LINUX_ADMIN],
        },
      },

      // 固件
      firmwareRoute,

      // 叉车固件
      forkliftFirmwareRoute,

      // 上位机
      softwareRouter,

      // 安卓屏幕
      androidScreenRoute,

      // ReactNative
      reactNativeRoute,

      // Linux
      linuxRoute,

      // 管理员
      ...adminRoute,
    ],
  },

  // 登录页
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
  },

  // 公开路由
  ...publicRoute,

  // 兜底
  {
    path: '/:w+',
    name: '*',
    component: () => import('@/views/login/index.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/**前置路由守卫 */
router.beforeEach(async (to, from, next) => {
  /**获取store中token数据 */
  const token = useAppStore().token;

  /**获取存储在session中的登录信息 */
  const loginParam = sessionStorage.getItem(LoginSessionKey.LOGIN_PARAM);

  const union_id = sessionStorage.getItem(LoginSessionKey.UNION_ID);

  /**根据token判断用户是否登录 */

  /** 已登陆 */
  if (token !== '') {
    if (to.path === '/login') next({ path: '/' });
    else next();
  } else {
    /** 未登录 */
    // 登录页
    if (['/login'].indexOf(to.path) !== -1) {
      next();
    }
    // 如果有缓存的登录信息
    else if (loginParam) {
      await loginMethod(JSON.parse(loginParam), true);
      next();
    }
    // 基于 union id 登录
    else if (union_id) {
      await loginByUnionId(union_id, true);
      next();
    }

    // 白名单直接进入
    else if (to.meta.permission.includes(Role.FREE)) {
      next();
    } else {
      /**其他页面需跳转至登录页 */
      next(`/login?redirect=${to.path}`);
    }
  }
});

/**路由后置守卫 */
router.afterEach((to) => {
  document.title = to.meta.title ? to.meta.title : '士腾科技 · OTA系统';
});

export default router;
