import { Role } from '@/router/enum';
import { RouteRecordRaw } from 'vue-router';

export const linuxRoute: RouteRecordRaw = {
  path: 'linux',
  name: 'linux',
  redirect: 'linux/publish',
  meta: {
    title: 'Linux固件',
    key: 'linux',
    icon: 'GroupOutlined',
    permission: [Role.ADMIN, Role.USER, Role.LINUX_ADMIN],
  },
  children: [
    {
      path: 'linux-publish',
      name: 'linux-publish',
      component: () => import('@/views/back-stage-management/Linux/index.vue'),
      meta: {
        title: '发布',
        key: 'linux-publish',
        icon: 'aim-outlined',
        permission: [Role.ADMIN, Role.USER],
      },
    },
    {
      path: 'linux-project',
      name: 'linux-project',
      component: () =>
        import('@/views/back-stage-management/Linux/page/project.vue'),
      meta: {
        title: '项目列表',
        key: 'linux-project',
        icon: 'ProfileOutlined',
        permission: [Role.ADMIN, Role.LINUX_ADMIN],
      },
    },
  ],
};
