import axios, { HttpStatusCode } from 'axios';
import { message } from 'ant-design-vue';
import { DEFAULT_URL } from '@/api/config';
import { useAppStore } from '@/store/appStore';
import router from '@/router';
import { RawAxiosRequestHeaders } from 'axios';
import { Toast } from '@/enum/ui';
import { LoginSessionKey } from '@/enum/app';

/**设置超时时间与基础请求地址 */
const request = axios.create({
  baseURL: DEFAULT_URL,
});

/**axios前置拦截器 */
request.interceptors.request.use(
  (config) => {
    const store = useAppStore();

    /**如果含有token则每次请求携带对应请求头*/
    (config.headers as RawAxiosRequestHeaders)['Authorization'] =
      'bearer ' + store.token || '';

    (config.headers as RawAxiosRequestHeaders)['x-acs-systech-access-token'] =
      store.dd_token || '';

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

/**axios后置拦截器 */
request.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    const store = useAppStore();
    const { response } = error;
    const data = response.data;

    /**status为400时为参数错误 */
    // if (error.response.status === 400) {
    //   /**用户提示 */
    //   message.warn({
    //     content: Array.isArray(error.response.data.message)
    //       ? error.response.data.message[0]
    //       : error.response.data.message,
    //     duration: 1,
    //   });
    // }

    if (data.statusCode !== HttpStatusCode.Ok) {
      data.message &&
        message.warn({
          content: Array.isArray(data.message) ? data.message[0] : data.message,
          duration: 1,
        });

      /**status为401时为验证失败 */
      if (data.statusCode === HttpStatusCode.Unauthorized) {
        store.clearLoginState();

        // 清空登录信息
        sessionStorage.removeItem(LoginSessionKey.LOGIN_PARAM);
        sessionStorage.removeItem(LoginSessionKey.UNION_ID);

        /**用户提示 */
        message.warn({ content: '登录状态过期', key: Toast.LoginExpiration });
        setTimeout(() => router.push('/login'), 500);
      }
    }

    return Promise.reject(error);
  },
);

export default request;
