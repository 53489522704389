import request from '@/api/instance/request';
import { AxiosPromise } from 'axios';
import { ILoginFormProp } from '@/utils/login';
import {
  CreateUserDto,
  initUserByUnionDto,
  LoggerListResponse,
  LoginResponse,
  UserListResponse,
  verifyUnionExistResponse,
} from '@/api/request/user/interface';
import { ApiCommResponse, PageTableParam } from '@/api/request/interface';

export const userApi = {
  /**
   * 新增用户
   * @param {string} data.username  用户名
   * @param {string} data.password  加密后的密码
   * @param {string} data.name  昵称
   * @param {boolean} data.isAdmin  管理员权限
   */
  insert: (data: CreateUserDto): AxiosPromise<ApiCommResponse> =>
    request.post('/user', data),

  /**
   * 用户登录
   * @param {string} data.username  用户名
   * @param {string} data.password  加密后的密码
   */
  login: (data: ILoginFormProp): AxiosPromise<LoginResponse> =>
    request.post('/user/login', data),
  /**
   * 获取用户列表
   * @param {number} data.pageSize  每页数量
   * @param {number} data.current  当前页数
   */
  list: (data: PageTableParam): AxiosPromise<UserListResponse> =>
    request.post('/user/query', data),
  /**
   * 修改用户状态
   * @param {string} data.username  用户名
   * @param {boolean} data.status  是否启用
   */
  changeStatus: (data: {
    username: string;
    status: boolean;
  }): AxiosPromise<ApiCommResponse> => request.post('/user/changeStatus', data),

  /**
   * 修改用户权限
   * @param {string} data.username  用户名
   * @param {string} data.permission  是否启用
   */
  changePermission: (data: {
    username: string;
    permission: string;
  }): AxiosPromise<ApiCommResponse> =>
    request.post('/user/changePermission', data),

  /**
   * 获取日志列表
   * @param {number} data.pageSize  每页数量
   * @param {number} data.current  当前页数
   */
  logList: (data: PageTableParam): AxiosPromise<LoggerListResponse> =>
    request.post('/user/logger', data),

  /**
   * 刷新token
   * */
  refreshToken: (): AxiosPromise<LoginResponse> =>
    request.post('/user/refreshToken'),

  /**
   * 钉钉登录
   * @param {string} union_id 钉钉 union_id
   */
  dingtalkLogin: (union_id: string): AxiosPromise<LoginResponse> =>
    request.post('/user/dingtalk_login', { union_id }),

  /**
   * 判断钉钉是否存在
   * @param {string} union_id 钉钉 union_id
   */
  checkUnionIdExist: (
    union_id: string,
  ): AxiosPromise<verifyUnionExistResponse> =>
    request.post('/user/check_union_id_exist', { union_id }),

  /**
   * 根据union_id初始化用户
   * @param {initUserByUnionDto} data 钉钉 union_id
   */
  initUserByUnionId: (
    data: initUserByUnionDto,
  ): AxiosPromise<ApiCommResponse> =>
    request.post('/user/init_user_by_union_id', data),
};
